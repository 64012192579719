import React, { useState } from 'react'
import { Box, HStack, Container, Heading, Text } from '@chakra-ui/react'
import Card from '../../../../../atoms/Card'
import FooterBase from '../../../../../molecules/FooterBase'
import { useNavigate, useLocation, useParams, useSearchParams } from 'react-router-dom'
import NavBar from '../../../../../atoms/NavBar'
import BackButton from '../../../../../atoms/nav-controls/BackButton'
import ContributorCaptureForm from './ContributorCaptureForm'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { claimMedia } from '../../../../../../../api/app/media'
import { useEventHandlersV2 } from '../../../../../../hooks/useEventHandlers'
import { useEventContext } from '../../../../../../contexts/EventProvider'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
	verifyEmail,
	checkAccount,
	getUserLocation,
	registerUser,
} from '../../../../../molecules/controls/InputEmail/utils'
import { addFlashMessage } from '../../../../../../../api/app/behaviors/flash-messages'
import { REGISTER_SUCCESS } from '../../../../../../../api/app/constants'
import schemaContributorRecipient from './ContributorCaptureForm/schema'

const Controls = () => {
	const { uuid } = useParams()
	const navigate = useNavigate()
	const location = useLocation()
	const { isOwner } = useEventContext()
	return (
		<NavBar pos={isOwner ? 'absolute' : 'relative'}>
			<HStack spacing={['4%', '1rem']}>
				<BackButton left onClick={() => navigate(`/e/${uuid}${location.search}`)} />
			</HStack>
		</NavBar>
	)
}

const AddRecipient = () => {
	const { event } = useEventContext()
	const dispatch = useDispatch()
	const location = useLocation()
	const navigate = useNavigate()
	const handlers = useEventHandlersV2(event.uuid)
	const [searchParams] = useSearchParams()
	const privateKey = searchParams.get('v')

	const [isSubmitting, setIsSubmitting] = useState(false)

	/** Retrieve uploads from redux */
	const uploadsFromStore = useSelector((s) => s.uploader.uploads, shallowEqual)

	/** if uploads in redux store are empty (user refreshed page), use the uploads from location state (which is persisted on refresh and throughout session) */
	const uploads = uploadsFromStore?.length > 0 ? uploadsFromStore : location?.state?.uploads

	/** Redirect to event root */
	const redirectToInvite = () => {
		navigate(`${handlers.public_root}${location.search}`, { state: { fromUpload: true } })
	}

	const methods = useForm({
		defaultValues: {
			email: null,
			firstName: null,
			lastName: null,
			notifications: { promos: false },
			useConsent: false,
		},
		resolver: yupResolver(schemaContributorRecipient),
	})

	const { setError } = methods

	const onSubmit = async (data) => {
		/** Let user know we've submitted the form */
		setIsSubmitting(true)

		/** Format email to be fullproof */
		data.email = String(data.email).trim().toLowerCase()

		/** First, let's make sure the email is valid. */
		const verify = await verifyEmail(data.email)

		/** If the email is not valid, let the user know */
		if (!verify.isValid) {
			setError('email', {
				type: 'error',
				message: 'Invalid email, please check your entry.',
			})
			setIsSubmitting(false)
		} else {
			/** If it is valid, proceed with registration + Submission */
			/** First, let's check the account to see if it exist */
			const check = await checkAccount(data.email)

			/** If the account check wasn't successful */
			if (!check.success) {
				dispatch(addFlashMessage('error', check.message))
			} else {
				/**
				 * Otherwise, we were able to verify the account.
				 */
				/** If the account already exist, retrieve the user */
				if (check.reason == 'ACCOUNT_EXISTS') {
					const account = check.data
					/** Claim uploaded thank you video */
					uploads.forEach((file) => {
						var newFile = { ...file }
						newFile.contributorId = account.id
						newFile.eventId = event.id
						newFile.socketReloadPage = true
						newFile.useConsent = data.useConsent
						newFile.isThankYouVideo = true
						// Workaround for claim media happening on failed uploads - only claim if id is set
						if (newFile.id) {
							dispatch(claimMedia(newFile))
						}
					})

					/** Redirect to event's invite page */
					redirectToInvite()
				} else if (check.reason == 'ACCOUNT_AVAILABLE') {
					let user = {
						...data,
						sendEmail: false, // Create the user only, don't send out email.
						setRegistered: false, // This user did not fill out the full register form
						location: null,
					}

					// Retrieve user location
					const userLocation = await getUserLocation()

					// Attach user location from state
					if (userLocation.success) {
						user.location = userLocation.data
					}

					/** Request to register the user */
					const registration = await registerUser(user)

					if (!registration.success) {
						dispatch(addFlashMessage('error', 'An error occured while trying to create an account.'))
					} else {
						/**
						 * Registration was processed successfully,
						 * let's update our user
						 *  */
						const usr = registration.data.data
						// Let's save our new user temporarely for compatibility
						dispatch({ type: REGISTER_SUCCESS, payload: registration.data })

						// Handle Multiple Media Files
						uploads.forEach((file) => {
							var newFile = { ...file }
							newFile.contributorId = usr.id
							newFile.eventId = event.id
							newFile.socketReloadPage = true
							newFile.useConsent = data.useConsent
							newFile.isThankYouVideo = true
							newFile.privateKey = privateKey
							// Workaround for claim media happening on failed uploads - only claim if id is set

							if (newFile.id) {
								dispatch(claimMedia(newFile))
							}
						})

						redirectToInvite()
					}
				}
			}

			if (typeof window !== 'undefined' && window) {
				window.scrollTo(0, 0)
			}
		}
	}

	return (
		<>
			<Controls />

			<Container maxW="container.sm" py="1rem" variant="main">
				<Box my="2rem" textAlign={['left', 'center']} mx={['1rem', 0]}>
					<Heading as="h2" size="xl" variant="hero">
						Well done!
					</Heading>
					<Text>Please fill out the form below so we can send your thanks to those who participated.</Text>
				</Box>

				<Card
					p={['1rem', '2rem']}
					alignItems="center"
					variant="main"
					textAlign="center"
					mx={['1rem', 0]}
					borderRadius={['xl']}>
					<FormProvider {...methods}>
						<ContributorCaptureForm event={event} onSubmit={onSubmit} isSubmitting={isSubmitting} />
					</FormProvider>
				</Card>

				<FooterBase />
			</Container>
		</>
	)
}

export default AddRecipient
